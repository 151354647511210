import React, { useState } from "react";
import FullTable from "../../components/datatable/FullTable";
import { Tag, Modal, Form, Input, Button } from "antd";
import { client } from "../../config/permissions";
import SOWizard from "./SOWizard";
import HttpRequest from './../../services/HttpRequest';
import Moment from "react-moment";
import autoCloseModal from './../../components/modal/AutoCloseModal';
import errorModal from './../../components/modal/errorModal';

function IclassTable() {
	const [tableRows, setTableRows] = useState([]);
	const [forceReload, setForceReload] = useState(false);
    const [agendamientoData, setAgendamientoData] = useState(null);

	const endpoints = {
		columns: "iclass/service_orders/datatables_struct/",
		rows: "iclass/service_orders/datatables/",
		delete: `iclass/service_orders/`,
	};

	const handleRows = (res) => {
		setTableRows(
			res.map((row) => ({
				...row,
                custom_cancel: <Button danger type='primary' onClick={()=>setAgendamientoData({id: row.ID})}>Cancelar</Button>,
                start_date: <Moment locale="es" format="DD/MM/YYYY HH:mm">{row.start_date}</Moment>,
                end_date: <Moment locale="es" format="DD/MM/YYYY HH:mm">{row.end_date}</Moment>,
                status: row.status && row.status.value
            }))
		);
	};

    const cancelAgendamiento = values => {
        const formData = new FormData();
        formData.append('motive', values.reason);
        HttpRequest.endpoint = 'iclass/service_orders/' + agendamientoData.id + '/delete_order/';
        HttpRequest.post(formData).then(res => {
            if (res && res.detail === "Service order cancelled") {
                autoCloseModal('Se ha cancelado la orden de servicio con éxito')
                setAgendamientoData(null);
                setForceReload(!forceReload)
            } else {
                errorModal('Ha ocurrido un error cancelando esta orden de servicio')
            }
        })
    }

	return (
		<>
			<FullTable
				forceReload={forceReload}
				modalContent={<SOWizard />}
				endpoints={endpoints}
				handleRows={handleRows}
				tableRows={tableRows}
				tableName="OS"
				permissions={client}
				modalWidth={800}
			/>
            <Modal visible={agendamientoData} footer={null} onCancel={()=>setAgendamientoData(null)}>
                <Form onFinish={cancelAgendamiento}>
                    <h1 className='center-text'>Cancelar agendamiento</h1>
                    <Form.Item label='Motivo' name='reason'>
                        <Input.TextArea />
                    </Form.Item>
                    <Button type='primary' block htmlType='submit'>Cancelar</Button>
                </Form>
            </Modal>
		</>
	);
}

export default IclassTable;
