const permissions = {
    template: {
        text: {
            view: 'communications.view_templatetextmodel',
            create: 'communications.add_templatetextmodel',
            edit: 'communications.change_templatetextmodel',
            delete: 'communications.delete_templatetextmodel',
        },
        call: {
            view: 'communications.view_templatecallmodel',
            create: 'communications.add_templatecallmodel',
            edit: 'communications.change_templatecallmodel',
            delete: 'communications.delete_templatecallmodel'
        },
        email: {
            view: 'communications.view_templateemailmodel',
            create: 'communications.add_templateemailmodel',
            edit: 'communications.change_templateemailmodel',
            delete: 'communications.delete_templateemailmodel'
        },
        whatsapp: {
            view: 'communications.view_templatewhatsappmodel',
            create: 'communications.add_templatewhatsappmodel',
            edit: 'communications.change_templatewhatsappmodel',
            delete: 'communications.delete_templatewhatsappmodel'
        }
    },
    massive: {
        view: 'communications.view_massivecommunicationsmodel',
        create: 'communications.add_massivecommunicationsmodel',
        edit: 'communications.change_massivecommunicationsmodel',
        delete: 'communications.delete_massivecommunicationsmodel'
    },
    cycle: {
        view: 'communications.view_cyclecommunicationsmodel',
        create: 'communications.add_cyclecommunicationsmodel',
        edit: 'communications.change_cyclecommunicationsmodel',
        delete: 'communications.delete_cyclecommunicationsmodel'
    },
    event: {
        view: 'communications.view_triggereventmodel',
        create: 'communications.add_triggereventmodel',
        edit: 'communications.change_triggereventmodel',
        delete: 'communications.delete_triggereventmodel'
    },
    eventOptions: {
        text: {
            view: 'communications.view_eventtextmodel'
        },
        call: {
            view: 'communications.view_eventcallmodel'
        },
        whatsapp: {
            view: 'communications.view_eventwhatsappmodel'
        },
        email: {
            view: 'communications.view_eventemailmodel'
        }
    },
    client: {
        view: 'customers.view_customermodel',
        create: 'customers.add_customermodel',
        edit: 'customers.change_customermodel',
        delete: 'customers.delete_customermodel'
    },
    typifications: {
        view: 'tickets.view_typifymodel',
        create: 'tickets.add_typifymodel',
        edit: 'tickets.change_typifymodel',
        delete: 'tickets.delete_typifymodel'
    },
    typifyCategories: {
        view: 'tickets.view_categorymodel',
        create: 'tickets.add_categorymodel',
        edit: 'tickets.change_categorymodel',
        delete: 'tickets.delete_categorymodel'
    },
    slackMessage: {
        view: 'tickets.view_typifyslackmessagemodel',
        create: 'tickets.add_typifyslackmessagemodel',
        edit: 'tickets.change_typifyslackmessagemodel',
        delete: 'tickets.delete_typifyslackmessagemodel'
    },
    typifyTables: {
        view: 'tickets.view_typifytablesmodel',
        create: 'tickets.add_typifytablesmodel',
        edit: 'tickets.change_typifytablesmodel',
        delete: 'tickets.delete_typifytablesmodel'
    },
    lead: {
        view: 'customers.view_leadcustomermodel',
        create: 'customers.add_leadcustomermodel',
        edit: 'customers.change_leadcustomermodel',
        delete: 'customers.delete_leadcustomermodel'
    },
    leadlet: {
        view: 'customers.view_leadletcustomermodel',
        create: 'customers.add_leadletcustomermodel',
        edit: 'customers.change_leadletcustomermodel',
        delete: 'customers.delete_leadletcustomermodel'
    },
    wizard: {
        create: ['customers.add_leadcustomermodel', 'customers.add_leadletcustomermodel', 'customers.add_customermodel'],
    },
    contact: {
        view: 'customers.view_leadletcontactmodel',
        create: 'customers.add_leadletcontactmodel',
        edit: 'customers.change_leadletcontactmodel',
        delete: 'customers.delete_leadletcontactmodel'
    },
    tipifyComments: {
        view: 'communications.view_internalchatmodel',
        create: 'communications.add_internalchatmodel',
        edit: 'communications.change_internalchatmodel',
        delete: 'communications.delete_internalchatmodel'
    },
    recontact: {
        view: 'tickets.view_newcontactmodel',
        create: 'tickets.add_newcontactmodel',
        edit: 'tickets.change_newcontactmodel',
        delete: 'tickets.delete_newcontactmodel'
    },
    escalationCategories: {
        view: "escalation_ti.view_categorymodel",
        create: "escalation_ti.add_categorymodel",
        edit: "escalation_ti.change_categorymodel",
        delete: "escalation_ti.delete_categorymodel"
    },
    escalationSlack: {
        view: 'escalation_ti.view_escalationslackmessagemodel',
        create: 'escalation_ti.add_escalationslackmessagemodel',
        edit: 'escalation_ti.change_escalationslackmessagemodel',
        delete: 'escalation_ti.delete_escalationslackmessagemodel'
    },
    escalationTables: {
        view: "escalation_ti.view_escalationtablesmodel",
        create: "escalation_ti.add_escalationtablesmodel",
        edit: "escalation_ti.change_escalationtablesmodel",
        delete: "escalation_ti.delete_escalationtablesmodel"
    },
    escalation: {
        view: 'escalation_ti.view_escalationmodel',
        create: 'escalation_ti.add_escalationmodel',
        edit: 'escalation_ti.change_escalationmodel',
        delete: 'escalation_ti.delete_escalationmodel'
    },
    escalationProblems: {
        view: 'escalation_ti.view_problemmodel',
        create: 'escalation_ti.add_problemmodel',
        edit: 'escalation_ti.change_problemmodel',
        delete: 'escalation_ti.delete_problemmodel'
    },
    escalationTests: {
        view: "escalation_ti.view_testmodel",
        create: "escalation_ti.add_testmodel",
        edit: "escalation_ti.change_testmodel",
        delete: "escalation_ti.delete_testmodel"
    },
    escalationSolutions: {
        view: "escalation_ti.view_solutionmodel",
        create: "escalation_ti.add_solutionmodel",
        edit: "escalation_ti.change_solutionmodel",
        delete: "escalation_ti.delete_solutionmodel"
    },
    removeEquipment: {
        view: 'formerCustomers.view_removeequipamentmodel',
        create: 'formerCustomers.add_removeequipamentmodel',
        edit: 'formerCustomers.change_removeequipamentmodel',
        delete: 'formerCustomers.delete_removeequipamentmodel'
    },
    removeEquipmentTables: {
        view: 'formerCustomers.view_removeequipamenttablesmodel',
        create: 'formerCustomers.add_removeequipamenttablesmodel',
        edit: 'formerCustomers.change_removeequipamenttablesmodel',
        delete: 'formerCustomers.delete_removeequipamenttablesmodel'
    },
    leadTables: {
        view: 'customers.view_leadtablesmodel',
        create: 'customers.add_leadtablesmodel',
        edit: 'customers.change_leadtablesmodel',
        delete: 'customers.delete_leadtablesmodel'
    },
    leadletTables: {
        view: 'customers.view_leadlettablesmodel',
        create: 'customers.add_leadlettablesmodel',
        edit: 'customers.change_leadlettablesmodel',
        delete: 'customers.delete_leadlettablesmodel'
    },
    qc: {
        inspection: {
            view: 'qc.view_qainspectionmodel',
            create: 'qc.add_qainspectionmodel',
            edit: 'qc.change_qainspectionmodel',
            delete: 'qc.delete_qainspectionmodel'
        },
        monitoreo: {
            view: 'qc.view_qamonitoringmodel',
            create: 'qc.add_qamonitoringmodel',
            edit: 'qc.change_qamonitoringmodel',
            delete: 'qc.delete_qamonitoringmodel'
        },
        monitoreoRecord: {
            view: 'qc.view_monitoringrecordmodel',
            create: 'qc.add_monitoringrecordmodel',
            edit: 'qc.change_monitoringrecordmodel',
            delete: 'qc.delete_monitoringrecordmodel'
        }
    },
    drives: {
        definition: {
            view: 'drives.view_drivedefinitionmodel',
            create: 'drives.add_drivedefinitionmodel',
            edit: 'drives.change_drivedefinitionmodel',
            delete: 'drives.delete_drivedefinitionmodel'
        },
        register: {
            view: 'drives.view_driveregistermodel',
            create: 'drives.add_driveregistermodel',
            edit: 'drives.change_driveregistermodel',
            delete: 'drives.delete_driveregistermodel'
        }
    },
    emails: {
        view: 'communications.view_emailmodel',
        create: 'communications.add_emailmodel',
        edit: 'communications.change_emailmodel',
        delete: 'communications.delete_emailmodel'
    },
    whatsapp: {
        view: 'communications.view_whatsappmodel'
    },
    textMessage: {
        view: 'communications.view_textmodel'
    },
    voiceCalls: {
        view: 'communications.view_voicecallmodel'
    },
    tech_support: {
        view: "tech_support.view_ticketmodel",
        delete: "tech_support.delete_ticketmodel",
    }
}

export const { tech_support, whatsapp, emails, qc, drives, removeEquipmentTables, removeEquipment, escalationSolutions, escalationTests, escalationProblems, escalation, escalationSlack, escalationTables, escalationCategories, tipifyComments, recontact, eventOptions, contact, wizard, leadlet, lead, typifyTables, slackMessage, typifyCategories, typifications, client, event, massive, cycle, template } = permissions;
export default permissions;