import axios from "axios";
import Config from "../config";
import ACTIONS from "./../redux/creator";
import store from "./../redux/store";

class HttpRequest {
    constructor() {
        this.config = this.config.bind(this);
        this.get = this.get.bind(this);
        this.post = this.post.bind(this);
        this.put = this.put.bind(this);
        this.delete = this.delete.bind(this);
    }

    static config() {
        return {
            headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("authorization")}`,
                //Authorization: `JWT ${sessionStorage.getItem("authorization")}`,
            },
        };
    }

    static async get(payload, id = null, version = true) {
        let request;
        // if (payload == null) {
        // 	payload = new FormData();
        // }
        // const operator = localStorage.getItem("operator");
        // payload.append("operator", operator);

        try {
            if (payload) {
                //Variable created to save header configuration defaults
                let getConfig = this.config();
                //Add the necessary parameters to the query
                getConfig["params"] = payload;
                request = await axios.get(
                    `${Config.API_URL}/${this.endpoint}`,
                    getConfig
                );
            } else {
                if (version) {
                    if (!id) {
                        request = await axios.get(
                            `${Config.API_URL}/${this.endpoint}`,
                            this.config()
                        );
                    } else {
                        request = await axios.get(
                            `${Config.API_URL}/${this.endpoint}/${id}`,
                            this.config()
                        );
                    }
                } else {
                    if (!id) {
                        request = await axios.get(
                            `${Config.DOMAIN}/${this.endpoint}`,
                            this.config()
                        );
                    } else {
                        request = await axios.get(
                            `${Config.DOMAIN}/${this.endpoint}/${id}`,
                            this.config()
                        );
                    }
                }
            }
            return request.data;
        } catch (error) {
            if (error.response) {
                store.dispatch(ACTIONS.setHttpErrorSystem(this.endpoint.includes('matrix') ? 'matrix' : 'iris'));
                store.dispatch(ACTIONS.setHttpError(error.response.status));
                return error.response.data;
            } else {
                console.log('Algo ha pasado con la petición. Intente nuevamente')
                return error
            }
        }
    }

    static async post(payload, enviroment = true, multipart = null) {
        let request = null;
        let getConfig = this.config();
        const operator = store.getState().operator;
        if (payload) {
            payload.append("operator", operator);
        }

        try {
            if (multipart != null) {
                getConfig.headers["Content-Type"] = multipart;
                request = await axios.post(
                    `${Config.API_URL}/${this.endpoint}`,
                    payload,
                    getConfig
                );
            } else {
                if (enviroment) {
                    request = await axios.post(
                        `${Config.API_URL}/${this.endpoint}`,
                        payload,
                        this.config()
                    );
                } else {
                    request = await axios.post(
                        `${Config.DOMAIN}/api/${this.endpoint}`,
                        payload,
                        this.config()
                    );
                }
            }
            return request.data;
        } catch (error) {
            if (error.response) {
                store.dispatch(ACTIONS.setHttpErrorSystem(this.endpoint.includes('matrix') ? 'matrix' : 'iris'));
                store.dispatch(ACTIONS.setHttpError(error.response.status));
                return error.response.data;
            } else {
                console.log('Algo ha pasado con la petición. Intente nuevamente')
                return error
            }
        }
    }

    static async put(id, payload, version = true) {
        let request = null;
        let op = store.getState().operator;
        payload.append("operator", op);

        try {
            if (version) {
                request = await axios.put(
                    `${Config.API_URL}/${this.endpoint}/${id}/`,
                    payload,
                    this.config()
                );
            } else {
                request = await axios.put(
                    `${Config.DOMAIN}/api/${this.endpoint}/${id}/`,
                    payload,
                    this.config()
                );
            }
            return request.data;
        } catch (error) {
            if (error.response) {
                store.dispatch(ACTIONS.setHttpErrorSystem(this.endpoint.includes('matrix') ? 'matrix' : 'iris'));
                store.dispatch(ACTIONS.setHttpError(error.response.status));
                return error.response.data;
            } else {
                console.log('Algo ha pasado con la petición. Intente nuevamente')
                return error
            }
        }
    }

    static async patch(id, payload, version = true) {
        let request = null;
        let op = store.getState().operator;
        payload.append("operator", op);

        try {
            if (version) {
                request = await axios.patch(
                    `${Config.API_URL}/${this.endpoint}${id}/`,
                    payload,
                    this.config()
                );
            } else {
                request = await axios.patch(
                    `${Config.DOMAIN}/api/${this.endpoint}${id}/`,
                    payload,
                    this.config()
                );
            }
            return request.data;
        } catch (error) {
            if (error.response) {
                store.dispatch(ACTIONS.setHttpErrorSystem(this.endpoint.includes('matrix') ? 'matrix' : 'iris'));
                store.dispatch(ACTIONS.setHttpError(error.response.status));
                return error.response.data;
            } else {
                console.log('Algo ha pasado con la petición. Intente nuevamente')
                return error
            }
        }
    }

    static async delete(id, version = true) {
        let request = null;

        try {
            if (version) {
                request = await axios.delete(
                    `${Config.API_URL}/${this.endpoint}${id}`,
                    this.config()
                );
            } else {
                request = await axios.delete(
                    `${Config.DOMAIN}/api/${this.endpoint}${id}`,
                    this.config()
                );
            }
            return request.data;
        } catch (error) {
            if (error.response) {
                store.dispatch(ACTIONS.setHttpErrorSystem(this.endpoint.includes('matrix') ? 'matrix' : 'iris'));
                store.dispatch(ACTIONS.setHttpError(error.response.status));
                return error.response.data;
            } else {
                console.log('Algo ha pasado con la petición. Intente nuevamente')
                return error
            }
        }
    }
}

export default HttpRequest;
