import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Modal, Checkbox, InputNumber, Alert, Row } from 'antd';
import HttpRequest from '../../services/HttpRequest';
import FilterComp from "./FilterComp";
import './TypifyTables.css';
import { can, validOperator } from '../../helpers/helpers';
import autoCloseModal from '../../components/modal/AutoCloseModal';

function TablesForm(props) {
    const [formLoading, setFormLoading] = useState(true);
    const [defaultData, setDefaultData] = useState({});
    const [columns, setColumns] = useState([]);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [filters, setFilters] = useState(null);
    const [comparisons, setComparisons] = useState(null);
    const [TOC, setTOC] = useState(null);
    const [renderFilters, setRenderFilters] = useState([]);
    const [nameOptions, setNameOptions] = useState([]);
    const [filtersValues, setFiltersValues] = useState([]);
    const [deleteIndex, setDeleteIndex] = useState({});
    const [error, setError] = useState(null);
    const [id, setId] = useState(0);

    const submitForm = e => {
        if (validOperator()) {
            setError(null);
            setButtonLoading(true);
            const formData = new FormData()
            Object.keys(e).forEach(key => {
                if (e[key] !== undefined && e[key] !== null) {
                    if (typeof e[key] === 'object') {
                        formData.append(key, JSON.stringify(e[key]))
                    } else {
                        formData.append(key, e[key])
                    }
                }
            })
            let filteredFilters = [];
            Object.keys(filtersValues).forEach((filter) => {
                if (filtersValues[filter]) {
                    filteredFilters.push(filtersValues[filter])
                }
            })
            formData.append('filters', JSON.stringify(Object.values(filteredFilters)))
            HttpRequest.endpoint = props.endpoints[0];
            if (props.id) {
                if (can(props.permissions.edit)) {
                    HttpRequest.patch(props.id, formData).then(res => {
                        setButtonLoading(false);
                        if (res.ID) {
                            autoCloseModal('Se ha editado la tabla con éxito')
                            props.closeModal()
                        } else {
                            Modal.error({
                                title: 'Error',
                                content: 'Ha ocurrido un error al editar la tabla'
                            })
                        }
                    })
                }
            } else {
                if (can(props.permissions.create)) {
                    HttpRequest.post(formData).then(res => {
                        setButtonLoading(false);
                        if (res.ID) {
                            props.closeModal()
                            autoCloseModal('Se ha creado la tabla con éxito')
                        } else {
                            Modal.error({
                                title: 'Error',
                                content: 'Ha ocurrido un error al crear la tabla'
                            })
                        }
                    })
                }
            }
        }
    }
    const getData = () => {
        HttpRequest.endpoint = props.endpoints[1];
        HttpRequest.get().then(res => {
            //COLUMNS
            const col = [];
            Object.keys(res.columns).forEach(column => {
                col.push(<Select.Option key={column} value={column}>{res.columns[column]}</Select.Option>)
            })
            setColumns(col);
            let tempTOC = {};
            for (let i = 0; i < Object.keys(res.filters).length; i++) {
                let filterType = Object.values(res.filters)[i].type;
                let arrToCompare = res.type_of_comparisons[filterType]
                let type = [];
                arrToCompare.forEach(element => {
                    type.push({
                        ID: Object.values(res.comparisons[element])[0],
                        name: element,
                    });
                });
                tempTOC[filterType] = type;
            }
            setNameOptions(Object.keys(res.filters).map(spaName => <Select.Option key={spaName} value={res.filters[spaName].name}>{spaName}</Select.Option>));
            setFilters(res.filters);
            setTOC(tempTOC);
            setComparisons(res.comparisons);
            getDefaultData();
        })
    }
    const getDefaultData = () => {
        if (props.id) {
            HttpRequest.endpoint = props.endpoints[2];
            HttpRequest.get(null, props.id).then(res => {
                setDefaultData(res);
                setFormLoading(false);
            })
        } else {
            setFormLoading(false);
        }
    }
    const deleteFilter = index => {
        setRenderFilters(prevState => {
            let indexToDelete;
            prevState.forEach((div, i) => {
                if (Number(div.key) === index) {
                    indexToDelete = i
                }
            })
            return prevState.slice(0, indexToDelete).concat(prevState.slice(indexToDelete + 1))
        });
        setDeleteIndex(prevState => {
            let keyToDelete;
            Object.keys(prevState).forEach(key => {
                if (prevState[key] === index) {
                    keyToDelete = key
                }
            })
            if (keyToDelete) {
                setFiltersValues(prevState => {
                    delete prevState[keyToDelete];
                    return prevState;
                })
            }
            delete prevState[keyToDelete];
            return prevState;
        })
    }
    const generateInitialFilters = () => {
        if (defaultData.filters) {
            const defaultFilters = []
            defaultData.filters.forEach(filter => {
                setId(prevState => {
                    defaultFilters.push(
                        <div key={prevState} className='form-filter'>
                            <FilterComp
                                nameOptions={nameOptions}
                                filters={filters}
                                comparison={comparisons}
                                typeOfComparison={TOC}
                                function={(key, name, comparison, value) => {
                                    const filterVal = [name, comparison, value];
                                    setDeleteIndex(prev => {
                                        let oldKey;
                                        let indexOfKey;
                                        Object.values(prev).forEach((ind, a) => {
                                            if (prevState === ind) {
                                                indexOfKey = a
                                            }
                                        });
                                        if (indexOfKey !== undefined) {
                                            oldKey = Object.keys(prev)[indexOfKey]
                                        }
                                        if (oldKey) {
                                            setFiltersValues(prevState => {
                                                delete prevState[oldKey]
                                                return {
                                                    ...prevState,
                                                    [key]: filterVal
                                                }
                                            })
                                        } else {
                                            setFiltersValues(prevState => {
                                                return {
                                                    ...prevState,
                                                    [key]: filterVal
                                                }
                                            })
                                        }
                                        return {
                                            ...prev,
                                            [key]: prevState
                                        }
                                    })
                                    setNameOptions(prevState => {
                                        return prevState.filter(opt => {
                                            return opt.key !== key
                                        })
                                    })
                                }}
                                initialVals={filter}
                            />
                            <Button danger type='primary' onClick={() => deleteFilter(prevState)}>X</Button>
                        </div>
                    )
                    return prevState + 1
                })
            })
            setRenderFilters(defaultFilters);
        }
    }
    const addNewFilter = () => {
        const actualFilters = [...renderFilters];
        actualFilters.push(
            <div key={id} className='form-filter'>
                <FilterComp
                    nameOptions={nameOptions}
                    filters={filters}
                    comparison={comparisons}
                    typeOfComparison={TOC}
                    function={(key, name, comparison, value) => {
                        const filterVal = [name, comparison, value];
                        setDeleteIndex(prevState => {
                            let oldKey;
                            let indexOfKey;
                            Object.values(prevState).forEach((ind, i) => {
                                if (id === ind) {
                                    indexOfKey = i
                                }
                            });
                            if (indexOfKey !== undefined) {
                                oldKey = Object.keys(prevState)[indexOfKey]
                            }
                            if (oldKey) {
                                setFiltersValues(prevState => {
                                    delete prevState[oldKey]
                                    return {
                                        ...prevState,
                                        [key]: filterVal
                                    }
                                })
                            } else {
                                setFiltersValues(prevState => {
                                    return {
                                        ...prevState,
                                        [key]: filterVal
                                    }
                                })
                            }
                            return {
                                ...prevState,
                                [key]: id
                            }
                        })
                        setNameOptions(prevState => {
                            return prevState.filter(opt => {
                                return opt.key !== key
                            })
                        })
                    }}
                />
                <Button danger type='primary' onClick={() => deleteFilter(id)}>X</Button>
            </div>
        )
        setRenderFilters(actualFilters);
        setId(prevState => prevState + 1);
    }

    useEffect(getData, [])
    useEffect(generateInitialFilters, [defaultData]);

    const initialValues = {
        name: defaultData ? defaultData.name : null,
        columns: defaultData ? defaultData.columns : [],
        assigned_to_me: defaultData ? defaultData['assigned_to_me'] : true,
        create_me: defaultData ? defaultData['create_me'] : true,
        last_time_type: defaultData ? defaultData['last_time_type'] : 0,
        last_time: defaultData ? defaultData['last_time'] : 0,
    }

    return (
        <>
            {!formLoading &&
                <>
                    <Form name='tables-form' initialValues={initialValues} onFinish={submitForm}>
                        <Form.Item name='name' label='Nombre' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name='columns' label='Columnas' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                            <Select mode='multiple' showSearch optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {columns}
                            </Select>
                        </Form.Item>
                        <Row className='create-typify-table-row'>
                            {!props.removeAsigned &&
                                <Form.Item name='assigned_to_me' label='Asignado a mi' valuePropName="checked" >
                                    <Checkbox />
                                </Form.Item>}
                            {!props.removeCreated &&
                                <Form.Item name='create_me' label='Créame' valuePropName="checked">
                                    <Checkbox />
                                </Form.Item>}
                            <Form.Item name='last_time_type' label='Tipo de tiempo'>
                                <Select placeholder='Seleccione'>
                                    <Select.Option value={0}>Minutos</Select.Option>
                                    <Select.Option value={1}>Horas</Select.Option>
                                    <Select.Option value={2}>Días</Select.Option>
                                    <Select.Option value={3}>Semanas</Select.Option>
                                    <Select.Option value={4}>Meses</Select.Option>
                                    <Select.Option value={5}>Trimestres</Select.Option>
                                    <Select.Option value={6}>Año</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item name='last_time' label='Tiempo'>
                                <InputNumber min={0} />
                            </Form.Item>
                        </Row>
                        <Button onClick={addNewFilter} type='primary' className='center-btn add-filter-btn'>Agregar filtro</Button>
                        {renderFilters}
                        <Button size='large' htmlType='submit' block type='primary' loading={buttonLoading}>{props.id ? 'Guardar' : 'Crear'}</Button>
                        {error && <Alert type='error' message={error} />}
                    </Form>
                </>
            }
        </>
    )
}

export default TablesForm;