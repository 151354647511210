import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { parsePhoneNumber } from "react-phone-number-input";
import React, { useState, useEffect, useRef } from "react";
import { Button, Select, Steps, Modal, Collapse } from "antd";
import HttpRequest from "../../services/HttpRequest.js";
import {
    endPointTemplateText,
    endPointTemplateCall,
    endPointTemplateEmail,
    endPointTemplateWhatsapp,
    endPointCreateEvent,
} from "../../services/endpoints.jsx";
import store from "../../redux/store.js";
import FormComp from "../../components/form/Form.jsx";
import EmailForm from "./emailForm.jsx"
import PhoneForm from "./phoneForm.jsx";
import { can, canWithoutMessage } from "../../helpers/helpers.js";
import { event, eventOptions } from "../../config/permissions.js";
import autoCloseModal from './../../components/modal/AutoCloseModal';

/**
 *
 * @param {props} props
 * required:
 * 		visible: bool, sets the modal status from the parent component
 * 		onCancel: function,  change the visible status of the modal so it can be closed from within itself too
 *
 * non required:
 * 		id: int: the event id in case we are going to edit it
 *
 *
 */

const EventCreateModal = (props) => {
    const [type, setType] = useState("Añadir canal de comunicación");
    const [showForm, setShowForm] = useState(false);
    const [showEvent, setShowEvent] = useState(false);
    const [number, setNumber] = useState();
    const [phoneParsed, setPhoneParsed] = useState(["CL", null]);
    const [template, setTemplates] = useState();
    const [templateList, setTemplateList] = useState();
    const [testEmail, setTestEmail] = useState("");
    const [responseEmail, setResponseEmail] = useState("");
    const [copyEmail, setCopyEmail] = useState("");
    const [step, setStep] = useState("");
    const [id, setId] = useState(props.id);
    const [textEvent, setTextEvent] = useState();
    const [callEvent, setCallEvent] = useState();
    const [whatsappEvent, setWhatsappEvent] = useState();
    const [emailEvent, setEmailEvent] = useState();
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [error, setError] = useState([]);
    const [exito, setExito] = useState("");
    const [loading, setLoading] = useState(false);
    const [channelContent, setChannelContent] = useState({ email: null })
    const formRef = useRef();
    const formRef2 = useRef();
    const { Option } = Select;
    const [resetChannel, setResetChannel] = useState(0)
    const { Step } = Steps;

    let info = {
        size: [6, 16],
        title: "Form1",
        items: [
            {
                custom: false,
                label: "Nombre",
                name: "name",
                type: "input",
                input: "string",
                rules: [{ validator: (_, value) => value ? !/\s/g.test(value) && !/[0-9]/g.test(value) ? Promise.resolve() : Promise.reject('El nombre no puede contener espacios ni números. Solo se permiten letras y _') : Promise.reject('Este campo es requerido') }],
                initialValue: id ? name : "",
            },
            {
                custom: false,
                label: "Descripción",
                name: "description",
                type: "textArea",
                input: "string",
                required: true,
                initialValue: id ? description : "",
            },
        ],
        multipart: false,
        submitButton: false,
    };

    let formTexto = {
        size: [6, 16],
        title: "Form1",
        items: [
            {
                custom: true,
                label: "Numero Prueba",
                name: "template",
                required: true,
                initialValue: number,
                component: (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                        <PhoneInput defaultCountry={phoneParsed[0]} style={{ width: "100%", }} value={phoneParsed[1]} onChange={setNumber} />
                    </div>
                ),
            },
            {
                custom: true,
                label: "Plantilla",
                name: "template",
                required: true,
                initialValue: template,
                component: (
                    <Select
                        placeholder="Seleccione la plantilla"
                        labelInValue
                        defaultValue={{
                            value: template,
                        }}
                        style={{
                            width: "100%",
                        }}
                        onChange={
                            handleSelectChange
                        }
                    >
                        {templateList}
                    </Select>
                ),
            },
        ],
        multipart: false,
        submitButton: false,
    };

    let formCorreo = {
        size: [6, 16],
        title: "Form1",
        items: [{
            custom: false,
            label: "Correo Prueba",
            name: "testEmail",
            type: "input",
            input: "email",
            required: true,
            initialValue: id ? testEmail : " ",
        }, {
            custom: false,
            label: "Correo Respuesta",
            name: "responseEmail",
            type: "input",
            input: "email",
            required: true,
            initialValue: id ? responseEmail : " ",
        }, {
            custom: false,
            label: "Correo Copia",
            name: "CopyEmail",
            type: "input",
            input: "email",
            required: true,
            initialValue: id ? copyEmail : " ",
        }, {
            custom: true,
            label: "Plantilla",
            name: "template",
            required: true,
            initialValue: template,
            component: (
                <Select
                    placeholder="Seleccione la plantilla"
                    labelInValue
                    defaultValue={{ value: template, }}
                    style={{ width: "100%", }}
                    onChange={handleSelectChange} >
                    {templateList}
                </Select>
            ),
        },
        ],
        multipart: false,
        submitButton: false,
    };

    let errors = {
        "Invalid name":
            "El nombre debe estar constituido solamente por letras o el caracter '_'. ",
    };

    /**
     * @function Updates the type of the channel we are currently adding/editing,
     * changing the displayed form and setting the initial values if necesary.
     * Also changes the select options dynamically checking the templates list in the db
     *
     * @param {string} key option selected in the menu
     */

    const selectItem = (key) => {

        setShowForm(false);
        setType(key["key"]);
        setError([]);
        setExito("");

        switch (key["key"]) {
            case "correo":
                if (emailEvent) {
                    HttpRequest.endpoint = emailEvent.split("v1/").pop();
                    HttpRequest.get().then((res) => {
                        setTestEmail(res["email_test"]);
                        setResponseEmail(res["email_response"]);
                        setCopyEmail(res["email_copy"]);
                        createOptions(endPointTemplateEmail);
                    });
                } else {
                    createOptions(endPointTemplateEmail);
                }
                break;

            case "texto":
                if (textEvent) {
                    HttpRequest.endpoint = textEvent.split("v1/").pop();
                    HttpRequest.get().then((res) => {
                        let phoneNumber = parsePhoneNumber(
                            res["phone_number_test"]
                        );
                        setPhoneParsed([
                            phoneNumber.country,
                            phoneNumber.nationalNumber,
                        ]);
                        setNumber(res["phone_number_test"]);
                        setTemplates(res["template"]);
                        createOptions(endPointTemplateText);
                    });
                } else {
                    createOptions(endPointTemplateText);
                }

                break;

            case "whatsapp":
                if (whatsappEvent) {
                    HttpRequest.endpoint = whatsappEvent.split("v1/").pop();
                    HttpRequest.get().then((res) => {
                        let phoneNumber = parsePhoneNumber(
                            res["phone_number_test"]
                        );
                        setPhoneParsed([
                            phoneNumber.country,
                            phoneNumber.nationalNumber,
                        ]);
                        setNumber(res["phone_number_test"]);
                        setTemplates(res["template"]);
                        createOptions(endPointTemplateWhatsapp);
                    });
                } else {
                    createOptions(endPointTemplateWhatsapp);
                }

                break;

            case "llamadas":
                if (callEvent) {
                    HttpRequest.endpoint = callEvent.split("v1/").pop();
                    HttpRequest.get().then((res) => {
                        let phoneNumber = parsePhoneNumber(
                            res["phone_number_test"]
                        );
                        setPhoneParsed([
                            phoneNumber.country,
                            phoneNumber.nationalNumber,
                        ]);
                        setNumber(res["phone_number_test"]);
                        setTemplates(res["template"]);
                        createOptions(endPointTemplateCall);
                    });
                } else {
                    createOptions(endPointTemplateCall);
                }
                break;
            default:
                break;
        }
    };

    /**
     * @function Retrieves the list of templates and updates the options of the select
     *
     * @param {string} endpoint the endpoint to make the request and get the list of templates
     */

    const createOptions = (endpoint) => {
        let templatesArray = [];
        HttpRequest.endpoint = endpoint;
        HttpRequest.get()
            .then((res) => {
                for (let index = 0; index < res.length; index++) {
                    templatesArray.push(
                        <Option key={res[index]["ID"]} value={res[index]["ID"]}>
                            {res[index]["name"]}
                        </Option>
                    );
                }
                setTemplateList(templatesArray);
            })
            .then(() => {
                setShowForm(true);
            });
    };

    /**
     * @function Retrieves the data for the event that we are about to edit. Setting the
     * initial values and the urls for the request
     *
     * @param {string} eventId the Id of the event that we are currently editing
     */

    async function getData(eventId) {
        let request = "";
        HttpRequest.endpoint = endPointCreateEvent + eventId;
        HttpRequest.get()
            .then((res) => {
                setName(res["name"]);
                setDescription(res["description"]);
                setTextEvent(res["text_event"]);
                setEmailEvent(res["email_event"]);
                setWhatsappEvent(res["whatsapp_event"]);
                setCallEvent(res["call_event"]);
            })
            .then(() => {
                setShowEvent(true);
            });
    }

    /**
     * @function Called everythime the modal is open/closed. Clears the form fields and
     * calls the function to retrieve the event data.
     * Then makes the form visible
     *
     * @param {string} eventId the Id of the event that we are currently editing
     */

    useEffect(() => {
        setName("");
        setDescription("");
        setShowEvent(false);
        if (id) {
            getData(id);
        } else {
            setShowEvent(true);
        }
    }, []);

    function handleSelectChange(option) {
        setTemplates(option["key"]);
    }

    /**
     * @function Makes the request to the endpoint passing the field values in order to create or update an
     * event depending on the props.id passed.
     *
     */

    const createEvent = () => {
        setError([])
        let op = store.getState().operator;
        if (!op || op == 0) {
            Modal.error({
                title: "Error",
                content: "Por favor seleccione un operador válido",
            });
            return;
        }
        setLoading(true);
        let values = null;
        const formData = new FormData();
        values = formRef.current.values();
        formData.append("name", values["name"]);
        formData.append("description", values["description"]);
        HttpRequest.endpoint = endPointCreateEvent;

        id
            ? can(event.edit) && HttpRequest.patch(id, formData).then((res) => {
                setLoading(false);
                if (res["ID"]) {
                    props.updateRows();
                    autoCloseModal("El evento ha sido actualizado exitosamente")
                    setId(id);
                    //setExito("");
                    //setError([]);
                } else {
                    //Modal.error({ title: "Error", content: "Ha ocurrido un error al modificar el evento" })
                    Object.values(res).forEach((element) => {
                        Object.keys(element).forEach((element2) => {
                            if (element2 in errors) {
                                Modal.error({ title: "Error", content: errors[element2] })
                            }
                        });
                    });
                }
            })
            : can(event.create) && HttpRequest.post(formData).then((res) => {
                setLoading(false);
                if (res["ID"]) {
                    props.updateRows();
                    autoCloseModal("El evento ha sido creado exitosamente")
                    setId(res["ID"]);
                    // setExito("");
                    //setError([]);
                } else {
                    Object.values(res).forEach((element) => {
                        Object.keys(element).forEach((element2) => {
                            if (element2 in errors) {
                                error.push(errors[element2] + "\n");
                                Modal.error({ title: "Error", content: errors[element2] })
                            }
                        });

                    });
                    //Modal.error({ title: "Error", content: "Ha ocurrido un error al crear el evento. " + error })
                }
            });
    };

    /**
     * @function Makes the request to the endpoint passing the field values depending on the type
     * in order to create or update a comumnication channel using the event id in the endpoint url.
     *
     */

    const addChannel = () => {
        setLoading(true);
        let values = formRef2.current.values();
        const formData = new FormData();
        if (type === "correo") {
            formData.append("channel", "email");
            formData.append("email_test", values["testEmail"]);
            formData.append("email_response", values["responseEmail"]);
            formData.append("email_copy", values["CopyEmail"]);
        } else {
            type === "texto"
                ? formData.append("channel", "text")
                : type == "whatsapp"
                    ? formData.append("channel", "whatsapp")
                    : formData.append("channel", "call");

            formData.append("phone_number_test", number);
        }
        formData.append("template", template);
        HttpRequest.endpoint = endPointCreateEvent + id + "/add_channel/";
        HttpRequest.post(formData).then((res) => {
            setLoading(false);
            if (Object.keys(res).length === 0) {
                props.updateRows();
                autoCloseModal("Canal eliminado exitosamente")
                setError([]);
            } else {
                error.push(
                    "Ha ocurrido un error al añadir el canal de comunicación"
                );
            }
        });
    };

    /**
     * @function Makes the request to the endpoint passing the field values depending on the type
     * in order to delete a comumnication channel using the event id in the endpoint url.
     *
     */

    const deleteChannel = (type) => {
        const formData = new FormData();
        formData.append("channel", type);
        HttpRequest.endpoint = endPointCreateEvent + id + "/remove_channel/";
        HttpRequest.post(formData).then((res) => {
            if (Object.keys(res).length === 0) {
                props.updateRows();
                setResetChannel(resetChannel + 1)
                autoCloseModal("Canal eliminado exitosamente")
            } else {
                Modal.error({
                    title: "Error",
                    content: "Ha ocurrido un error al eliminar el canal de comunicación"
                })
            }
        });
    };

    // useEffect(() => {
    //     setChannelContent({ email: <EmailForm handleSelectChange={handleSelectChange} id={id} emailEvent={emailEvent} deleteChannel={deleteChannel} /> })
    //     console.log("Cambio");
    // }, [id])


    return (
        <div>
            {showEvent && (
                <Collapse accordion defaultActiveKey={['Event']}>
                    <Collapse.Panel key="Event" header="Evento">
                        <div>
                            <FormComp ref={formRef} customSubmit={createEvent} info={info} />
                            <Button className='center-btn' type="primary" onClick={() => formRef.current.submit()} >
                                Guardar
                        </Button>
                        </div>
                    </Collapse.Panel>
                    {canWithoutMessage(eventOptions.text.view) && <Collapse.Panel key="text" header="Canal de texto">
                        <PhoneForm
                            editing={props.id ? true : false}
                            permissions={{
                                create: event.create,
                                edit: event.edit,
                                delete: event.delete,
                            }}
                            type="text"
                            event={textEvent}
                            id={id}
                            deleteChannel={deleteChannel}
                            reset={resetChannel}
                            updateRows={props.updateRows}
                        />
                    </Collapse.Panel>}
                    {canWithoutMessage(eventOptions.call.view) && <Collapse.Panel key="call" header="Canal de llamadas">
                        <PhoneForm
                            editing={props.id ? true : false}
                            permissions={{
                                create: event.create,
                                edit: event.edit,
                                delete: event.delete,
                            }}
                            type="call"
                            event={callEvent}
                            id={id}
                            deleteChannel={deleteChannel}
                            reset={resetChannel}
                            updateRows={props.updateRows}
                        />
                    </Collapse.Panel>}
                    {canWithoutMessage(eventOptions.whatsapp.view) && <Collapse.Panel key="whatsapp" header="Canal de WhatsApp">
                        <PhoneForm
                            editing={props.id ? true : false}
                            permissions={{
                                create: event.create,
                                edit: event.edit,
                                delete: event.delete,
                            }}
                            type="whatsapp"
                            event={whatsappEvent}
                            id={id}
                            deleteChannel={deleteChannel}
                            reset={resetChannel}
                            updateRows={props.updateRows}
                        />
                    </Collapse.Panel>}
                    {canWithoutMessage(eventOptions.email.view) && <Collapse.Panel key="email" header="Canal de correo">
                        <EmailForm
                            editing={props.id ? true : false}
                            permissions={{
                                create: event.create,
                                edit: event.edit,
                                delete: event.delete,
                            }}
                            handleSelectChange={handleSelectChange}
                            id={id}
                            type="email"
                            emailEvent={emailEvent}
                            deleteChannel={deleteChannel}
                            reset={resetChannel}
                            updateRows={props.updateRows}
                        />
                    </Collapse.Panel>}
                </Collapse>
            )}
            <p>{error}</p>
        </div>
    );
};

export default EventCreateModal;
