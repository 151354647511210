import React, { useState } from 'react';
import FullTable from '../../components/datatable/FullTable';
import { Modal, Button } from 'antd';
import { endPointLead } from "../../services/endpoints";
import LeadCreateForm from "../leads/LeadCreateForm";
import HttpRequest from "../../services/HttpRequest";
import "./leads.css";
import { can } from '../../helpers/helpers';
import { lead, leadlet } from '../../config/permissions';
import autoCloseModal from '../../components/modal/AutoCloseModal';

function Lead2(props) {
    const [tableRows, setTableRows] = useState([]);
    const [forceReload, setForceReload] = useState(false);

    const endpoints = {
        columns: 'customers/lead_customer/' + props.tableID + '/datatables_struct/',
        rows: 'customers/lead_customer/' + props.tableID + '/datatables/',
        delete: 'customers/lead_customer/',
        download: 'customers/lead_customer/' + props.tableID + '/download_data/',
    }
    const errors = {
        "Error address feasibility": "La dirección no tiene factibilidad",
        "Instance is leadlef": "Ya ha sido convertido a prospecto",
    };
    const convertToProspect = id => {
        if (can(lead.edit) && can(leadlet.create)) {
            HttpRequest.endpoint = "customers/lead_customer/" + id + "/change_to_leadlet/";
            HttpRequest.post().then(res => {
                if (res != {}) {
                    Object.keys(res).forEach(element => {
                        Modal.error({
                            title: "Error",
                            content:
                                "Ha ocurrido un error al convertir este lead. " +
                                errors[element],
                        });
                    });
                } else {
                    autoCloseModal("Se ha convertido a prospecto con éxito")
                    setForceReload(prevState => !prevState);
                }
            });
        }
    };

    const handleRows = res => {
        setTableRows(res.map(row => ({
            ...row,
            custom_to_customer: <Button type="primary" className="form-control" onClick={() => convertToProspect(row.ID)}>Prospecto</Button>
        })))
    }
    return (
        <>
            <FullTable buttons={[]} modalWidth={800} permissions={lead} forceReload={forceReload} modalContent={<LeadCreateForm hideLastName lead={true} endpoint={endPointLead} />} endpoints={endpoints} handleRows={handleRows} tableRows={tableRows} tableName='lead' />
        </>
    )
}

export default Lead2;