import React, { useState, useEffect } from 'react';
import HttpRequest from './../../services/HttpRequest';
import { connect } from "react-redux";
import errorModal from './../../components/modal/errorModal';
import { Form, Spin, Input, Button, Divider, Upload } from 'antd';
import Config from "../../config";
import { UploadOutlined } from '@ant-design/icons';

function Chat(props) {
    const [messages, setMessages] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [users, setUsers] = useState(null)

    const submit = e => {
        if (e.file && e.file.fileList.length > 0) {
            if (e.file.fileList.length > 1) {
                return errorModal('Solo puede enviar 1 archivo a la vez')
            }
            sendFile(e);
        } else {
            sendMessage(e)
        }
    }
    const sendMessage = async e => {
        try {
            setButtonLoading(true)
            const user = JSON.parse(localStorage.getItem('userInfo'));
            const url = Config.API_URL + `/slackapp/chat.postMessage?username=${user.username}&operator=${props.operator}&text=${e.message}&channel=${props.channel}&thread_ts=${props.ts}`
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                }
            })
            if (response.ok) {
                const data = await response.json();
                if (data.ok) {
                    setButtonLoading(false)
                    setRefresh(!refresh)
                } else {
                    setButtonLoading(false)
                    errorModal('Ha ocurrido un error respondiendo a Slack')
                }
            } else {
                setButtonLoading(false)
                errorModal('Ha ocurrido un error.')
            }
        } catch (err) {
            console.log(err)
            setButtonLoading(false)
            errorModal('Ha ocurrido un error.')
        }
    }
    const sendFile = async e => {
        try {
            setButtonLoading(true)
            const formData = new FormData();
            formData.append('file', e.file.fileList[0].originFileObj)
            const user = JSON.parse(localStorage.getItem('userInfo'));
            const url = Config.API_URL + `/slackapp/files.upload?username=${user.username}&operator=${props.operator}&text=${e.message}&channel=${props.channel}&thread_ts=${props.ts}`
            const response = await fetch(url, {
                method: 'POST',
                body: formData
            })
            if (response.ok) {
                const data = await response.json();
                if (data.ok) {
                    setButtonLoading(false)
                    setRefresh(!refresh)
                }
            } else {
                setButtonLoading(false)
                errorModal('Ha ocurrido un error respondiendo a Slack')
            }
        } catch (err) {
            console.log(err)
            setButtonLoading(false)
            errorModal('Ha ocurrido un error.')
        }
    }
    useEffect(() => {
        HttpRequest.endpoint = 'slackapp/conversations.replies';
        HttpRequest.get({ operator: props.operator, channel: props.channel, ts: props.ts }).then(res => {
            if (res.ok) {
                setMessages(res.messages)
            } else {
                errorModal('Ha ocurrido un error obteniendo el hilo de Slack')
            }

        })
    }, [props.operator, refresh])

    useEffect(() => {
        HttpRequest.endpoint = 'user/user_description/slack_uids/';
        HttpRequest.get().then(res => {
            const userIds = {};
            if (res && res.data) {
                res.data.forEach(obj => {
                    Object.assign(userIds, obj)
                })
                setUsers(userIds)
            }
        })
    }, [])

    return (
        <>
            {users && messages ?
                <>
                    <h1 className='center-text'>Hilo de Slack</h1>
                    <Divider />
                    {messages.map((msg, i) => {
                        if (msg.type === 'message') {
                            const user = msg.username ? msg.username : msg.user && users[msg.user] ? users[msg.user] : msg.bot_profile ? msg.bot_profile.name : ''
                            const text = [];
                            if (msg.text === "No se puede mostrar este contenido.") {
                                msg.blocks.forEach((block, i) => {
                                    if (block.type === 'section') {
                                        let textMsg = block.text.text;
                                        const userRegex = /<@[A-Z0-9]+>/;
                                        if (userRegex.test(textMsg)) {
                                            const userCapture = /<@([A-Z0-9]+)>/gi;
                                            const userList = [...textMsg.matchAll(userCapture)]
                                            userList.forEach(userArr => { textMsg = textMsg.replace(userArr[0], users[userArr[1]]) })
                                        }
                                        const asteriskRegex = /\*[a-z]+@[a-z]+\.[a-z]+\*/
                                        if (asteriskRegex.test(textMsg)) {
                                            const asteriskCapture = /\*([a-z]+@[a-z]+\.[a-z]+)\*/gi;
                                            const asteriskList = [...textMsg.matchAll(asteriskCapture)];
                                            asteriskList.forEach(asteriskArr => { textMsg = textMsg.replace(asteriskArr[0], asteriskArr[1]) })
                                        }
                                        text.push(<p key={i}><strong>{user}: </strong>{textMsg}</p>)
                                    }
                                })
                            } else {
                                text.push(<p key={i}><strong>{user}: </strong>{msg.text}</p>)
                            }
                            return (
                                <div key={i}>
                                    <div className='chat-msg'>
                                        {text}
                                        {msg.files && msg.files.map((file, i) => {
                                            if (file.mimetype.includes('image')) {
                                                return <a key={i} rel='noopener noferrer' target='_blank' href={file.url_private}>
                                                    <div>
                                                        <img
                                                            style={{ width: '100%', height: 'auto', 'marginBottom': '10px' }}
                                                            src={file.url_private}
                                                            //file.thumb_1024 || file.thumb_960 || file.thumb_800 || file.thumb_720 || file.thumb_480 || file.thumb_360 || file.thumb_160
                                                            alt={file.name}
                                                        />
                                                    </div>
                                                </a>
                                            } else {
                                                return <p key={i}>Archivo adjunto: <a target='_blank' href={file.url_private} rel='noopener noferrer'>Click aquí para ver archivo</a></p>
                                            }
                                        })}
                                    </div>
                                    <Divider />
                                </div>
                            )
                        } else {
                            return null
                        }
                    })}
                    <Form onFinish={submit}>
                        <Form.Item name='message' label='Respuesta' rules={[{ required: true, message: 'Este campo es requerido' }]}>
                            <Input placeholder='Respuesta' />
                        </Form.Item>
                        <Form.Item name='file' label='Archivo'>
                            <Upload beforeUpload={() => false}>
                                <Button className='center-btn' icon={<UploadOutlined />}>Agregar archivo</Button>
                            </Upload>
                        </Form.Item>
                        <Button loading={buttonLoading} block htmlType='submit' type='primary'>Enviar</Button>
                    </Form>
                </>
                :
                <Spin className='center-spinner' size='large' />
            }
        </>
    )
}
function mapStateToProps(state) {
    return {
        operator: state.operator,
    };
}

export default connect(mapStateToProps)(Chat)
