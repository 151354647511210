import React from 'react';
import FullTable from '../../components/datatable/FullTable';
import { typifyTables } from '../../config/permissions';
import TablesForm from './TablesForm';

function TypificationsTable(props) {
    const tableEndpoints = {
        columns: 'tickets/typify_tables/datatables_struct/',
        rows: 'tickets/typify_tables/datatables/',
        delete: 'tickets/typify_tables/',
    }
    const formEndpoints = ['tickets/typify_tables/', 'tickets/typify_tables/definition/', 'tickets/typify_tables']

    return (
        <>
            <FullTable
                permissions={props.permissions || typifyTables}
                modalWidth={700}
                modalContent={<TablesForm removeAsigned={props.removeCreated} removeCreated={props.removeCreated} endpoints={props.endpoints ? props.endpoints.formEndpoints : formEndpoints} permissions={props.permissions || typifyTables} />}
                endpoints={props.endpoints ? props.endpoints.tableEndpoints : tableEndpoints}
                tableName='tabla'
            />
        </>
    )
}

export default TypificationsTable;