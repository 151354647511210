import React, { useState, useEffect } from 'react';
import { DataTable, ShowFilters } from "../table/ListTable";
import { Panel, PanelBody } from "../panel/panel";
import ButtonWithModal from "../buttons/ButtonWithModal.jsx";
import { Space, Button, Modal, Drawer, Input, Pagination, ConfigProvider } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import HttpRequest from "../../services/HttpRequest.js";
import Moment from "react-moment";
import { connect } from "react-redux";
import './FullTable.css'
import { can } from '../../helpers/helpers';
import esES from 'antd/lib/locale/es_ES';

/*
    props = {
        endpoints = {
            columns: '',                  to get table structure
            rows: '',                     to get table tabledata
            delete: '',                   to delete a table record (required if using default delete button)
            download: ''                  to download table data (only in cases where available)
        }
        permissions = {
            create: '',                   permission to create new item
            edit: '',                     permission to edit item
            delete: ''                    permission to delete item
        }
        handleRows = function()           function to handle rows response, handle rows data in parent (optional)
        tableRows = array of rows         rows to render in table (optional, required when using handleRows)
        tableName = '',                   to show in modals, success and error messages, singular (required if using default create, edit or delete buttons)
        modalContent = JSX Component      component to render in create and edit modals (required if using default create or edit buttons)
        modalWidth = number               width in px representing modal width
        customRendering = object          objects with keys renderFunction and/or keys. renderFunction replaces generateDefaultRowFields function and keys is an optional array to render another field
        buttons = array of buttons        buttons to render instead of default create button (optional)
        forceReload = boolean             variable to force fetch of rows. Anytime this variable changes will force row fetching (optional)
    }
*/

function FullTable(props) {
    const [tableColumns, setTableColumns] = useState([]);
    const [tableRows, setTableRows] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const [reloadRows, setReloadRows] = useState(false);
    const [rowsParams, setRowsParams] = useState(null);
    const [showDrawer, setShowDrawer] = useState(false);
    const [filters, setFilters] = useState(null);
    const [defaultFilters, setDefaultFilters] = useState(null);
    const [comparisons, setComparisons] = useState(null);
    const [TOC, setTOC] = useState(null);
    const [queryFilters, setQueryFilters] = useState({});
    const [drawerKey, setDrawerKey] = useState(1);
    const [scrollWidth, setScrollWidth] = useState(0);
    const [downloadButton, setDownloadButton] = useState(false);
    const [email, setEmail] = useState(null);
    const [searchValue, setSearchValue] = useState('');
    const [totalSize, setTotalSize] = useState(0);
    const [showPagination, setShowPagination] = useState(false);
    const [paginationSettings, setPaginationSettings] = useState([])

    let CONSTANTS = [
        "created",
        "custom_change",
        "custom_delete"
    ];

    if (props.customRendering && props.customRendering.keys) {
        CONSTANTS = CONSTANTS.concat(props.customRendering.keys)
    }

    const generateDefaultRowFields = (text, value, id = null) => {
        if (props.customRendering) {
            return props.customRendering.renderFunction(text, value, id)
        } else {
            switch (value) {
                case "created":
                    return <Moment format="DD/MM/YYYY">{text}</Moment>;
                case "custom_change":
                    return <ButtonWithModal
                        name='Editar'
                        title={'Editar ' + props.tableName}
                        //onCancel={() => setReloadRows(prevState => !prevState)}
                        onCancel={reload => reload && setReloadRows(prevState => !prevState)}
                        content={React.cloneElement(props.modalContent, { id: id })}
                        width={props.modalWidth}
                    />
                case "custom_delete":
                    return <Button type='primary' className="form-control" danger onClick={() => deleteRow(id)}>Borrar</Button>
                default:
                    break;
            }
        }
    };

    const getColumns = () => {
        setTableLoading(true);
        HttpRequest.endpoint = props.endpoints.columns;
        HttpRequest.get({ operator: props.operator }).then(res => {
            const columnTitles = Object.keys(res.columns);
            const columns = []
            columnTitles.forEach((columnTitle, i) => {
                columns.push({
                    title: columnTitle,
                    dataIndex: res.columns[columnTitle].field,
                    key: res.columns[columnTitle].field,
                    sorter: Object.values(res.columns)[i].sortable,
                    fixed: res.columns[columnTitle].fixed,
                    width: res.columns[columnTitle].width,
                    render: CONSTANTS.includes(Object.values(res.columns)[i].field) ?
                        (text, next) => generateDefaultRowFields(text, Object.values(res.columns)[i].field, next["ID"]) : "",
                })
            })
            setScrollWidth(res.defaults.scroll);
            setTableColumns(columns);
            setRowsParams(res.defaults);
            setDownloadButton(res.defaults.download);
            let tempTOC = {};
            for (let i = 0; i < Object.keys(res.filters).length; i++) {
                let filterType = Object.values(res.filters)[i].type;
                let arrToCompare = res.type_of_comparisons[filterType]
                let type = [];
                arrToCompare.forEach(element => {
                    type.push({
                        ID: Object.values(res.comparisons[element])[0],
                        name: element,
                    });
                });
                tempTOC[filterType] = type;
            }
            setFilters(res.filters);
            setDefaultFilters(res.defaults.filters);
            setTOC(tempTOC);
            setComparisons(res.comparisons);
        }).catch(err => console.log(err))
    }
    const getRows = () => {
        if (rowsParams) {
            setShowPagination(false);
            let data = new FormData();
            data.set(`${Object.keys(rowsParams)[0]}`, `${Object.values(rowsParams)[0]}`);
            data.set(`${Object.keys(rowsParams)[1]}`, `${Object.values(rowsParams)[1]}`);
            data.set(`${Object.keys(rowsParams)[2]}`, `${Object.values(rowsParams)[2]}`);
            data.set(`${Object.keys(rowsParams)[3]}`, `${Object.values(rowsParams)[3]}`);
            data.set(`${Object.keys(rowsParams)[4]}`, JSON.stringify(Object.values(rowsParams)[4]));
            data.set(`${Object.keys(rowsParams)[5]}`, `${Object.values(rowsParams)[5]}`);
            setTableLoading(true);
            HttpRequest.endpoint = props.endpoints.rows;
            HttpRequest.post(data).then(res => {
                if (res) {
                    if (res.size) {
                        setTotalSize(res.size);
                        setShowPagination(true);
                    }
                    if (res.result) {
                        if (props.handleRows) {
                            props.handleRows(res.result)

                        } else {
                            setTableRows(res.result.map(row => ({
                                ...row,
                                key: row.ID
                            })))
                        }
                        setTableLoading(false);
                    }
                }
            })
        }
    }
    const deleteRow = id => {
        if (can(props.permissions.delete)) {
            Modal.confirm({
                title: '¿Está seguro que desea eliminar este registro?',
                icon: <ExclamationCircleOutlined />,
                cancelText: 'Cancelar',
                onOk() {
                    HttpRequest.endpoint = props.endpoints.delete;
                    HttpRequest.delete(id).then(res => {
                        if (res.status === 400) {
                            Modal.error({
                                title: 'Error',
                                content: 'Ha ocurrido un error eliminando este item'
                            })
                        } else {
                            Modal.success({
                                title: 'Éxito',
                                content: 'Se ha eliminado el item'
                            })
                            setReloadRows(prevState => !prevState)
                        }
                    })
                },
            });
        }
    }
    const downloadData = () => {
        if (email && /\S+@\S+\.\S+/.test(email)) {
            if (rowsParams) {
                let data = new FormData();
                data.set(`${Object.keys(rowsParams)[0]}`, `${Object.values(rowsParams)[0]}`);
                data.set(`${Object.keys(rowsParams)[1]}`, `${Object.values(rowsParams)[1]}`);
                data.set(`${Object.keys(rowsParams)[2]}`, `${Object.values(rowsParams)[2]}`);
                data.set(`${Object.keys(rowsParams)[3]}`, `${Object.values(rowsParams)[3]}`);
                data.set(`${Object.keys(rowsParams)[4]}`, JSON.stringify(Object.values(rowsParams)[4]));
                data.set(`${Object.keys(rowsParams)[5]}`, `${Object.values(rowsParams)[5]}`);
                data.set('email', email)
                HttpRequest.endpoint = props.endpoints.download;
                HttpRequest.post(data).then(res => {
                    if (Object.keys(res).length === 0) {
                        Modal.success({
                            title: 'Éxito',
                            content: 'Se ha enviado el archivo a su correo.'
                        })
                    } else {
                        Modal.error({
                            title: 'Error',
                            content: 'Ha ocurrido un error'
                        })
                    }
                })
            }
        } else {
            Modal.error({
                title: 'Error',
                content: 'Por favor especifique un email válido'
            })
        }
    }
    const updateRowsParams = (keyToUpdate, newValue) => {
        setRowsParams(prevState => ({
            ...prevState,
            [keyToUpdate]: newValue
        }))
    }
    const onSearch = e => {
        e.preventDefault();
        setSearchValue(e.target.value);
        if (e.key === 'Enter' || e.target.value === '') {
            const { name, value } = e.target;
            updateRowsParams(name, value);
        }
    };
    const onSearchClick = () => {
        updateRowsParams('search', searchValue)
    }

    const setFiltersValue = (key, name, comparison, value) => {
        let arrayFilter = [name, comparison, value];
        let updatedObject = queryFilters;
        Object.assign(updatedObject, { [key]: arrayFilter });
        setQueryFilters(prevState => ({
            ...prevState,
            [key]: arrayFilter
        }))
        updateRowsParams("filters", Object.values(updatedObject));
    };
    const onChange = e => {
        updateRowsParams("order_field", e.sorter.field);
        e.sorter.order === "ascend"
            ? updateRowsParams("order_type", "asc")
            : updateRowsParams("order_type", "desc");
    };

    const modifyPagination = (page, offset) => {
        if (offset !== rowsParams.offset) {
            updateRowsParams('offset', offset)
        } else {
            updateRowsParams('start', page * offset - offset)
        }
        setPaginationSettings([page, offset])
    }
    const resetFilters = () => {
        setQueryFilters({});
        updateRowsParams("filters", defaultFilters);
        setDrawerKey(drawerKey + 1);
    }

    useEffect(getColumns, [])
    useEffect(getRows, [rowsParams, reloadRows, props.forceReload, props.operator])
    return (
        <>
            <Panel>
                <PanelBody >
                    <div className='table-btns'>
                        {downloadButton &&
                            <div className="input-group">
                                <Input placeholder='Email' onChange={e => setEmail(e.target.value)} className='table-download-input' />
                                <Button className="table-btn" type='primary' onClick={downloadData}>Descargar</Button>
                            </div>
                        }
                        <div className="form-group table-align-right">
                            <Space>
                                {props.buttons ?
                                    props.buttons.map(button => button)
                                    :
                                    <ButtonWithModal
                                        name='Crear'
                                        title={'Crear ' + props.tableName}
                                        onCancel={reload => reload && setReloadRows(prevState => !prevState)}
                                        //onCancel={() => setReloadRows(prevState => !prevState)}
                                        content={props.modalContent}
                                        width={props.modalWidth}
                                        permission={props.permissions.create}
                                    />
                                }
                                <div className="input-group">
                                    <input
                                        name="search"
                                        type="text"
                                        className="form-control table-search-button"
                                        placeholder="Buscar"
                                        onKeyUp={onSearch}
                                    />
                                    <div className="input-group-append">
                                        <button onClick={onSearchClick} className="btn btn-outline-secondary" title="Buscar">
                                            <i className="fas fa-search"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="input-group">
                                    <Button className="table-btn" type='primary' onClick={() => setShowDrawer(true)}>Filtros</Button>
                                </div>
                            </Space>
                        </div>
                    </div>
                    <DataTable
                        onChange={onChange}
                        pagination={false}
                        loading={tableLoading}
                        size='default'
                        column={tableColumns}
                        dataSource={props.tableRows || tableRows}
                        scroll={{ x: scrollWidth }}
                    />
                    {showPagination &&
                        <ConfigProvider locale={esES}>
                            <Pagination
                                defaultPageSize={paginationSettings[1]}
                                defaultCurrent={paginationSettings[0]}
                                onChange={modifyPagination}
                                total={totalSize}
                                className='table-pagination-fix'
                            />
                        </ConfigProvider>
                    }
                    {showPagination && <p className='center-text'>Existe un total de {totalSize} registros</p>}
                    <Drawer
                        drawerStyle={{ paddingTop: 48 }}
                        title="Filtros"
                        width={670}
                        onClose={() => setShowDrawer(false)}
                        visible={showDrawer}
                        bodyStyle={{ paddingBottom: 80 }}
                        key={drawerKey}
                        footer={
                            <div className="form-group" style={{ textAlign: "right" }}>
                                <Button type="danger" onClick={() => resetFilters()} style={{ marginRight: 8 }}>Resetear</Button>
                                <Button type="primary" onClick={() => setShowDrawer(false)} style={{ marginRight: 8 }}>Aplicar</Button>
                            </div>
                        }
                    >
                        {filters ?
                            Object.values(filters).map((value, index) => (
                                <ShowFilters
                                    key={index}
                                    name={Object.keys(filters)[index]}
                                    filters={filters}
                                    comparison={comparisons}
                                    typeOfComparison={TOC}
                                    function={setFiltersValue}
                                />
                            ))
                            : ""
                        }
                    </Drawer>
                </PanelBody>
            </Panel>
        </>
    )
}

function mapStateToProps(state) {
    return {
        operator: state.operator,
    };
}

export default connect(mapStateToProps)(FullTable);