import React, { useState } from 'react';
import FullTable from '../../components/datatable/FullTable';
import { Modal, Button, Tag } from 'antd';
import { endPointLeadlet, endPointLeadTableStruct, endPointLeadDatatable } from "../../services/endpoints";
import LeadCreateForm from "../leads/LeadCreateForm";
import HttpRequest from "../../services/HttpRequest";
import { can } from '../../helpers/helpers';
import ButtonWithModal from "../../components/buttons/ButtonWithModal.jsx";
import ContactForm from './ContactForm';
import { leadlet, client } from '../../config/permissions';
import Wizard from '../wizard/Wizard';

function LeadletTable2(props) {
    const [tableRows, setTableRows] = useState([]);
    const [forceReload, setForceReload] = useState(false);
    const [prospectId, setProspectId] = useState(null);

    const endpoints = {
        columns: 'customers/leadlet_customer/' + props.tableID + '/datatables_struct/',
        rows: 'customers/leadlet_customer/' + props.tableID + '/datatables/',
        delete: 'customers/leadlet_customer/',
        download: 'customers/leadlet_customer/' + props.tableID + '/download_data/',
    }

    const createCustomer = res => {
        if (res.length > 0) {
            Modal.error({
                title: "Error",
                content:
                    "Ha ocurrido un error al convertir este prospecto a cliente",
            });
        } else {
            Modal.success({
                title: "Éxito",
                content:
                    "Se ha convertido a cliente exitosamente",
            });
        }
    }

    const handleRows = res => {
        setTableRows(res.map(row => ({
            ...row,
            custom_contacts: <Button type='primary' onClick={() => setProspectId(row.ID)}>Contacto</Button>,
            is_customer: row.is_customer ?
                <Tag color="green">Ya es cliente</Tag>
                :
                <ButtonWithModal
                    name="Convertir"
                    title="Convertir a cliente"
                    onCancel={() => setForceReload(prevState => !prevState)}
                    content={<Wizard LeadletId={row.ID} step={2} searchInfo={true} />}
                    width={1050}
                    permission={[leadlet.edit, client.create]}
                />
        })))
    }
    return (
        <>
            <FullTable modalWidth={1000} permissions={leadlet} buttons={[]} forceReload={forceReload} modalContent={<LeadCreateForm endpoint={endPointLeadlet} hideLastName />} endpoints={endpoints} handleRows={handleRows} tableRows={tableRows} tableName='prospecto' />
            <Modal footer={null} title='Contacto' visible={prospectId} onCancel={() => setProspectId(null)} destroyOnClose>
                <ContactForm id={prospectId} />
            </Modal>
        </>
    )
}

export default LeadletTable2;