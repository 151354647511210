import React from "react";
import { connect } from "react-redux";
import { withRouter, Redirect, Link } from "react-router-dom";
import { PageSettings } from "./../config/page-settings.js";
import LoginSVG from "../assets/images/login.svg";
import { isAuthenticated } from "./../helpers/helpers";

import UserService from "./../services/UserService";
import AuthenticationService from "./../services/AuthenticationService";
import ACTIONS from "../redux/creator";
import HttpRequest from "../services/HttpRequest.js";

class Login extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            listBg: [
                "/assets/img/login-bg/login-bg-2.jpg",
                "/assets/img/login-bg/login-bg-19.png",
                "/assets/img/login-bg/login-bg-20.jpg",
                "/assets/img/login-bg/login-bg-21.jpg",
                "/assets/img/login-bg/login-bg-22.jpg",
            ],
            activeBg: "",
            error: null,
            isLogged: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    selectBg() {
        let listBg = this.state.listBg;
        this.setState({
            activeBg: listBg[Math.floor(Math.random() * listBg.length)],
        });
    }

    componentDidMount() {
        this.selectBg();
        this.context.handleSetPageSidebar(false);
        this.context.handleSetPageHeader(false);
        this.setState({ isLogged: isAuthenticated() });
    }

    componentWillUnmount() {
        this.context.handleSetPageSidebar(true);
        this.context.handleSetPageHeader(true);
    }

    async handleSubmit(event) {
        event.preventDefault();
        this.setState({ error: '' })
        const auth = await AuthenticationService.login(
            event.target.username.value,
            event.target.password.value
        );
        if (auth && auth.token) {
            localStorage.setItem("authorization", auth.token);
            localStorage.setItem("token-date", Date.now());
            HttpRequest.endpoint = 'user/data/user_permissions/'
            HttpRequest.get().then(res => {
                if (Array.isArray(res)) {
                    localStorage.setItem('permissions', JSON.stringify(res))
                    HttpRequest.endpoint = "user/data/user_information/";
                    HttpRequest.get().then(res => {
                        if (res && res.groups && Array.isArray(res.groups)) {
                            localStorage.setItem('groups', JSON.stringify(res.groups))
                            localStorage.setItem('userInfo', JSON.stringify(res))
                            this.props.history.push("/");
                        } else {
                            AuthenticationService.logout();
                            this.setState({ error: "Ha ocurrido un error obteniendo los grupos. Por favor intente nuevamente" });
                        }
                    })
                } else {
                    AuthenticationService.logout();
                    this.setState({ error: "Ha ocurrido un error obteniendo los permisos. Por favor intente nuevamente" });
                }
            })
        } else {
            if (auth['non_field_errors'] && auth['non_field_errors'][0] === "Unable to log in with provided credentials.") {
                this.setState({ error: "Usuario/Contraseña no coinciden" });
            } else {
                this.setState({ error: "Ha ocurrido un error iniciando sesión" });
            }
        }
    }

    render() {
        const { error, isLogged } = this.state;

        return (
            <React.Fragment>
                {isLogged ? <Redirect to="/" /> : null}
                <div className="login-cover">
                    <div
                        className="login-cover-image"
                        style={{
                            backgroundImage: "url(" + this.state.activeBg + ")",
                        }}
                    ></div>
                    <div className="login-cover-bg"></div>
                </div>

                <div className="login login-v2">
                    <div className="login-header">
                        <div className="brand">Bienvenidos a Iris</div>
                    </div>
                    <div className="login-content">
                        <div className="container">
                            <div className="row">
                                {/* <div className="col-5">
									<img src={LoginSVG} alt="Login" />
								</div> */}
                                <form
                                    className="col-10 pl-10"
                                    onSubmit={this.handleSubmit}
                                >
                                    <div className="form-group m-b-20">
                                        <input
                                            name="username"
                                            className={`form-control form-control-lg ${error ? "is-invalid" : ""
                                                }`}
                                            placeholder="Username"
                                            autoComplete="none"
                                            required
                                        />
                                    </div>
                                    <div className="form-group m-b-20">
                                        <input
                                            type="password"
                                            name="password"
                                            className={`form-control form-control-lg ${error ? "is-invalid" : ""
                                                }`}
                                            placeholder="Password"
                                            required
                                        />
                                    </div>
                                    {/*<div className="checkbox checkbox-css m-b-20">
                                        <input
                                            type="checkbox"
                                            id="remember_checkbox"
                                            name="remember"
                                        />
                                        <label htmlFor="remember_checkbox">
                                            Recuérdame
                                        </label>
                                    </div>*/}
                                    <div className="form-group">
                                        <p className="text-danger text-center">
                                            {error}
                                        </p>
                                    </div>
                                    <div className="login-buttons">
                                        <button
                                            type="submit"
                                            className="btn btn-primary btn-block btn-lg"
                                        >
                                            Iniciar sesión
										</button>
                                    </div>
                                    <div className="form-group text-center mt-2">
                                        <Link to="/recuperar-contraseña">
                                            Olvidé mi contraseña
										</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
    setUser(payload) {
        dispatch(ACTIONS.setUser(payload));
    },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
