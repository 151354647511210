import React, { useState } from "react";
import { Form, Select, Button } from "antd";
import LocationForm from "../../components/form/locationInput";
import HttpRequest from "./../../services/HttpRequest";

function ServiceOrder(props) {
	const [osTypes, setOsTypes] = useState(null);
    const [location, setLocation] = useState(null);

	const submitForm = (e) => {
		const selectedOsIndex = Object.keys(osTypes).indexOf(e.type);
		props.setInfo(Object.assign({ osTypes }, { selectedOsIndex }, {location}));
		props.setStep(2);
	};

	const searchOsTypes = (address) => {
		HttpRequest.endpoint = "iclass/service_orders/pulso_os/";
		HttpRequest.get().then((res) => {
			if (res && typeof res === "object") {
				setOsTypes(res);
                setLocation(address)
			}
		});
	};

	return (
		<Form name="service-order" onFinish={submitForm}>
			<Form.Item label="Dirección" name="address">
				<LocationForm
					className="full-width"
					drive
					driveEdit
					onChange={(val) => searchOsTypes(val)}
				/>
			</Form.Item>
			{osTypes && (
				<>
					<Form.Item
						label="Tipo"
						name="type"
						rules={[
							{
								required: true,
								message: "Este campo es requerido",
							},
						]}
					>
						<Select placeholder="Seleccione">
							{Object.keys(osTypes).map((osName) => (
								<Select.Option key={osName} value={osName}>
									{osName}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
					{/*<Form.Item label="Asunto" name="about">
						<Input placeholder="Asunto" />
                    </Form.Item>*/}
					<Button
						type="primary"
						htmlType="submit"
						className="center-btn"
					>
						Ver disponibilidad
					</Button>
				</>
			)}
		</Form>
	);
}

export default ServiceOrder;
