import React from "react";
import Home from "./../pages/home/Home";
import AllTypifications from "../pages/typifications/all-typifications";
import Login from "../pages/login";
import Preferences from "./../pages/settings/Preferences";
import RecoveryPassword from "../pages/recovery-password";
import CustomerInformation from "../pages/common/CustomerInformation";
import Wizard from "../pages/wizard/Wizard";
import Cliente2 from "../pages/client/Client2";
import Cycle2 from "../pages/cycle/Cycle2";
import Massive2 from "../pages/massive/Massive2";
import Event2 from "../pages/event/Event2";
import LeadSearchTables from "../pages/leads/LeadSearchTable";
import LeadletSearchTables from "../pages/leadlet/LeadletSearchTable";
import Template2 from "../pages/template/Template2";
import Categories from "../pages/category/Categories";
import TypificationsSlack from "../pages/typifications/TypificationsSlack";
import TypificationsTable from "../pages/TypificationsTables/TypificationsTables";
import SearchTables from "../pages/TypificationsTables/SearchTables";
import ProofDataTable from "../pages/common/ProofDatatable";
import { can, canGroup } from "../helpers/helpers";
import permissions from "./permissions";
import EscalationCustomTables from "../pages/escalations/EscalationCustomTables";
import EscalationSearchTables from "../pages/escalations/EscalationSearchTables";
import EscalationSlack from "../pages/escalations/EscalationSlack";
import EscalationConfigForm from "../pages/escalations/EscalationConfigForm";
import RemoveEquipSearchTable from "../pages/removeEquipment/removeEquipSearchTables";
import ServiceOrder from "../pages/iclass/ServiceOrder";
import TimePicker from "../pages/iclass/TimePicker";
import WizardController from "./../pages/payment-wizards/WizardController";
import EmailSearchTables from "./../pages/emails/EmailSearchTable";
import MonitoreoTable from "./../pages/qc/MonitoreoTable";
import InspeccionesSearchTables from "./../pages/qc/InspeccionesSearchTables";
import { Modal } from "antd";
import DrivesDefinitionTable from "../pages/drives/DrivesDefinitionTable";
import DriveForm from "../pages/drives/DriveForm";
import DrivesSearchTables from "./../pages/drives/DrivesSearchTables";
import WhatsappSearchTables from "./../pages/whatsapp/WhatsappSearchTable";
import TextSearchTables from "./../pages/textMessages/TextSearchTable";
import CallsSearchTables from "./../pages/calls/CallsSearchTable";
import TicketsWizard from "./../pages/tickets/TicketsWizard";
import TicketSearchTables from "./../pages/tickets/TicketSearchTable";
import IclassTable from "./../pages/iclass/IclassTable";

/**
 * @constant {object [path,title,component]} routes
 * This content all of the routes of project
 */
const routes = [
	{
		path: "/login",
		title: "Login",
		component: () => <Login />,
		object: <Login />,
	},
	{
		path: "/recuperar-contraseña",
		title: "Recuperar",
		component: () => <RecoveryPassword />,
		object: <RecoveryPassword />,
	},
	{
		path: "/",
		exact: true,
		private: true,
		title: "Dashboard",
		component: () => <Home />,
		object: <Home />,
	},
	{
		path: "/proof_data_tables",
		title: "Tabla de prueba",
		private: true,
		exact: true,
		component: () => <ProofDataTable />,
		object: <ProofDataTable />,
	},
	{
		path: "/typifications",
		title: "Tipificaciones",
		private: true,
		exact: true,
		component: () => <AllTypifications />,
		object: <AllTypifications />,
	},
	{
		path: "/configuracion/preferencias",
		title: "Preferencias",
		private: true,
		exact: true,
		component: () => <Preferences />,
		object: <Preferences />,
	},
	{
		path: "/customInformation",
		title: "Información del Cliente",
		private: true,
		exact: true,
		component: () => <CustomerInformation />,
		object: <CustomerInformation />,
	},
	{
		path: "/templateText",
		title: "Plantilla",
		private: true,
		exact: true,
		component: () =>
			can(permissions.template.text.view) && <Template2 type={"text"} />,
		object: <Template2 />,
	},
	{
		path: "/templateCall",
		title: "Plantilla",
		private: true,
		exact: true,
		component: () =>
			can(permissions.template.call.view) && <Template2 type={"call"} />,
		object: <Template2 />,
	},
	{
		path: "/templateWhatsapp",
		title: "Plantilla",
		private: true,
		exact: true,
		component: () =>
			can(permissions.template.whatsapp.view) && (
				<Template2 type={"whatsapp"} />
			),
		object: <Template2 />,
	},
	{
		path: "/templateEmail",
		title: "Plantilla",
		private: true,
		exact: true,
		component: () =>
			can(permissions.template.email.view) && (
				<Template2 type={"email"} />
			),
		object: <Template2 />,
	},
	{
		path: "/event",
		title: "Eventos",
		private: true,
		exact: true,
		component: () => can(permissions.event.view) && <Event2 />,
		object: <Event2 />,
	},
	{
		path: "/massive",
		title: "Masivos",
		private: true,
		exact: true,
		component: () => can(permissions.massive.view) && <Massive2 />,
		object: <Massive2 />,
	},
	{
		path: "/cycle",
		title: "Ciclo",
		private: true,
		exact: true,
		component: () => can(permissions.cycle.view) && <Cycle2 />,
		object: <Cycle2 />,
	},

    {
        path: "/futuro-cliente/leads",
        title: "Leads",
        private: true,
        exact: true,
        component: () => can(permissions.lead.view) && <LeadSearchTables />,
        object: <LeadSearchTables />,
    },
    {
        path: "/futuro-cliente/prospectos",
        title: "Prospecto",
        private: true,
        exact: true,
        component: () => can(permissions.leadlet.view) && <LeadletSearchTables type='leadlet' />,
        object: <LeadletSearchTables />,
    },
    {
        path: "/clientes/informacion-contacto",
        title: "Client",
        private: true,
        exact: true,
        component: () => can(permissions.client.view) && <Cliente2 />,
        object: <Cliente2 />,
    },
    {
        path: "/futuro-cliente/wizard",
        title: "Wizard",
        private: true,
        exact: true,
        component: () => can(permissions.wizard.create[0]) && can(permissions.wizard.create[1]) && can(permissions.wizard.create[2]) && <Wizard />,
        object: <Wizard />,
    },
    {
        path: "/categorias",
        title: "Categorías",
        private: true,
        exact: true,
        component: () => can(permissions.typifyCategories.view) && <Categories />,
        object: <Categories />,
    },
    {
        path: "/clientes/tipificaciones-slack",
        title: "Tipificaciones Slack",
        private: true,
        exact: true,
        component: () => can(permissions.slackMessage.view) && <TypificationsSlack />,
        object: <TypificationsSlack />
    },
    {
        path: "/tipificaciones/tablas",
        title: "Tablas",
        private: true,
        exact: true,
        component: () => can(permissions.typifyTables.view) && <TypificationsTable />,
        object: <TypificationsTable />,
    },
    {
        path: "/tipificaciones/busqueda",
        title: "Búsqueda de tipificaciones",
        private: true,
        exact: true,
        component: () => can(permissions.typifications.view) && <SearchTables />,
        object: <SearchTables />
    },
    {
        path: "/escalamientos/tablas",
        title: "Escalamientos",
        private: true,
        exact: true,
        component: () => can(permissions.escalationTables.view) && <EscalationCustomTables />,
        object: <EscalationCustomTables />,
    },
    {
        path: "/escalamientos/busqueda",
        title: "Escalamientos",
        private: true,
        exact: true,
        component: () => {
            if (can(permissions.escalation.view) && (canGroup("Administrador TI") || canGroup("Agente TI nivel tres") || canGroup("Agente TI nivel dos") || canGroup("Agente TI nivel uno"))) {
                return <EscalationSearchTables />
            } else {
                Modal.error({
                    title: 'Error',
                    content: 'Usted no tiene permiso para realizar esta acción.'
                })
                return null
            }
        },
        object: <EscalationSearchTables />,
    },
    {
        path: "/escalamientos/slack",
        title: "Tablas",
        private: true,
        exact: true,
        component: () => can(permissions.escalationSlack.view) && <EscalationSlack />,
        object: <EscalationSlack />,
    },
    {
        path: "/escalamientos/problemas",
        title: "Problemas",
        private: true,
        exact: true,
        component: () => can(permissions.escalationProblems.view) && <EscalationConfigForm />,
        object: <EscalationConfigForm />,
    },
    {
        path: "/antiguos-clientes/retiro",
        title: "Tablas",
        private: true,
        exact: true,
        component: () => can(permissions.removeEquipment.view) && <RemoveEquipSearchTable />,
        object: <RemoveEquipSearchTable />,
    },
    {
        path: "/iclass/orden-servicio",
        title: "Orden de servicio",
        private: true,
        exact: true,
        component: () => <ServiceOrder />,
        object: <ServiceOrder />,
    },
    {
        path: "/iclass/timepicker",
        title: "Orden de servicio",
        private: true,
        exact: true,
        component: () => <TimePicker />,
        object: <TimePicker />,
    },
    {
        path: "/messages/email",
        title: "Correos",
        private: true,
        exact: true,
        component: () => can(permissions.emails.view) && <EmailSearchTables />,
        object: <EmailSearchTables />
    },
    {
        path: "/drives/definicion",
        title: "Definiciones de drives",
        private: true,
        exact: true,
        component: () => can(permissions.drives.definition.view) && <DrivesDefinitionTable />,
        object: <DrivesDefinitionTable />,
    },
    {
        path: "/drives/tablas",
        title: "Tablas de drives",
        private: true,
        exact: true,
        component: () => can(permissions.drives.register.view) && <DrivesSearchTables />,
        object: <DrivesSearchTables />,
    },
    {
        path: "/qc/inspecciones",
        title: "Inspecciones",
        private: true,
        exact: true,
        component: () => can(permissions.qc.inspection.view) && <InspeccionesSearchTables />,
        object: <InspeccionesSearchTables />,
    },
    {
        path: "/qc/monitoreo",
        title: "Monitoreo exploratorio",
        private: true,
        exact: true,
        component: () => can(permissions.qc.monitoreo.view) && <MonitoreoTable />,
        object: <MonitoreoTable />,
    },
    {
        path: "/messages/whatsapp",
        title: "Whatsapp",
        private: true,
        exact: true,
        component: () => can(permissions.whatsapp.view) && <WhatsappSearchTables />,
        object: <WhatsappSearchTables />,
    },
    {
        path: "/messages/text",
        title: "Texto",
        private: true,
        exact: true,
        component: () => can(permissions.textMessage.view) && <TextSearchTables />,
        object: <TextSearchTables />,
    },
    {
        path: "/messages/calls",
        title: "Llamadas",
        private: true,
        exact: true,
        component: () => can(permissions.voiceCalls.view) && <CallsSearchTables />,
        object: <CallsSearchTables />,
    },
    {
        path: "/soporte",
        title: "Soporte Técnico",
        private: true,
        exact: true,
        component: () => can(permissions.tech_support.view) && <TicketSearchTables />,
        object: <TicketSearchTables />,
    },
    {
		path: "/iclass/tabla",
		title: "Ordenes de servicio",
		private: true,
		exact: true,
		component: () => <IclassTable />,
		object: <IclassTable />,
	},
    {
        path: "/horarios/ausencia",
        title: "Ausencia",
        private: true,
        exact: true,
        component: () => <iframe width='100%' height='500px' style={{border: '1px solid transparent'}} src="https://fs2.iclass.com.br/iclassfs/restrict/equipe_ausencia_search.seam"></iframe>,
        object: <iframe src="https://fs2.iclass.com.br/iclassfs/restrict/equipe_ausencia_search.seam"></iframe>,
    },
    {
        path: "/horarios/tecnicos",
        title: "Técnicos",
        private: true,
        exact: true,
        component: () => <iframe width='100%' height='500px' style={{border: '1px solid transparent'}} src="https://fs2.iclass.com.br/iclassfs/restrict/equipe_search.seam"></iframe>,
        object: <iframe src="https://fs2.iclass.com.br/iclassfs/restrict/equipe_search.seam"></iframe>,
    },
    {
        path: "/horarios/periodos",
        title: "Períodos",
        private: true,
        exact: true,
        component: () => <iframe width='100%' height='500px' style={{border: '1px solid transparent'}} src="https://fs2.iclass.com.br/iclassfs/restrict/tipoperiodo_search.seam"></iframe>,
        object: <iframe src="https://fs2.iclass.com.br/iclassfs/restrict/tipoperiodo_search.seam"></iframe>,
    },
    {
        path: "/horarios/turnos",
        title: "Turnos",
        private: true,
        exact: true,
        component: () => <iframe width='100%' height='500px' style={{border: '1px solid transparent'}} src="https://fs2.iclass.com.br/iclassfs/restrict/turno_search.seam"></iframe>,
        object: <iframe src="https://fs2.iclass.com.br/iclassfs/restrict/turno_search.seam"></iframe>,
    },
];

export default routes;
