import React, { useState, useEffect } from 'react';
import { Timeline, Modal } from 'antd';
import HttpRequest from '../../services/HttpRequest';
import Moment from "react-moment";
import { WhatsAppOutlined, MailOutlined, PhoneOutlined, MessageOutlined } from '@ant-design/icons';

function CommunicationHistory(props) {
    const [communications, setCommunications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    const getCommunicationHistory = () => {
        HttpRequest.endpoint = 'customers/customer/' + props.customerID + '/communications_history/';
        HttpRequest.get().then(res => {
            if (res.length > 0) {
                const messages = []
                res.forEach(msg => {
                    messages.push(
                        <Timeline.Item
                            dot={msg.type === 'email' ? <MailOutlined className='timeline-icon' /> : msg.type === 'voice_call' ? <PhoneOutlined className='timeline-icon' /> : msg.type === 'whatsapp' ? <WhatsAppOutlined className='timeline-icon' /> : <MessageOutlined className='timeline-icon' />}
                            key={msg.created}
                        >
                            <strong>{<Moment format='DD/MM/YYYY' locale='es'>{msg.created}</Moment>}: </strong>{msg.message}.
                        </Timeline.Item>
                    )
                })
                setCommunications(messages);
            } else {
                setError('Este cliente no posee comunicaciones previas.')
            }
            setLoading(false);
        }).catch(err => {
            console.log(err)
            Modal.error({
                title: 'Error',
                content: 'Ha ocurrido un error consultando el historial de comunicaciones de este cliente.'
            })
        })
    }

    useEffect(getCommunicationHistory, [])

    return (
        <>
            {!loading && !error ?
                <Timeline mode='alternate' id='client-communication-history'>
                    {communications}
                </Timeline>
                : error
            }
        </>
    )
}

export default CommunicationHistory;