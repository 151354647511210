import { canWithoutMessage } from "../../helpers/helpers";

const readPermissions = {
    client: "read",
    textTemplate: "read",
    callTemplate: "read",
    whatsappTemplate: "read",
    emailTemplate: "read",
    event: "read",
    cycle: "read",
    massive: "read",
    slackMessage: "read",
    lead: "read",
    leadlet: "read",
    opportunity: "read",
    wizard: "read",
    typifyCategories: "read",
    typifyTables: "read",
    typifications: "read",
};

const Menu = [
    { path: "/", icon: "fa fa-th", title: "Dashboard" },

    {
        path: "/communication",
        icon: "fas fa-phone-square-alt",
        title: "Comunicaciones",
        children: [
            {
                path: "/template",
                title: "Plantillas",
                children: [
                    { path: "/templateText", title: "Plantillas de Texto", permission: readPermissions.textTemplate },
                    { path: "/templateCall", title: "Plantillas de llamada", permission: readPermissions.callTemplate },
                    { path: "/templateWhatsapp", title: "Plantillas de WhatsApp", permission: readPermissions.whatsappTemplate },
                    { path: "/templateEmail", title: "Plantillas de correo", permission: readPermissions.emailTemplate },
                ]
            },
            {
                path: "/event",
                title: "Evento",
                permission: readPermissions.event
            },
            {
                path: "/massive",
                title: "Masivos",
                permission: readPermissions.massive
            },
            {
                path: "/cycle",
                title: "Ciclos",
                permission: readPermissions.cycle
            },
            {
                path: "/messages",
                title: "Mensajes",
                children: [
                    { path: "/messages/email", title: "Correos" },
                    { path: "/messages/whatsapp", title: 'Whatsapp' },
                    { path: "/messages/text", title: 'Texto' },
                    { path: "/messages/calls", title: 'Llamadas' },
                ]
            }
        ]
    },
    {
        path: "/clientes",
        icon: "fa fa-address-card",
        title: "Clientes",
        children: [
            { path: "/clientes/informacion-contacto", title: "Información de contacto", permission: readPermissions.client },
        ]
    },
    {
        path: "/futuro-cliente",
        icon: "fa fa-user-plus",
        title: "Futuro Cliente",
        children: [
            { path: "/futuro-cliente/wizard", title: "Wizards", permission: readPermissions.wizard },
            { path: "/futuro-cliente/prospectos", title: "Prospectos", permission: readPermissions.leadlet },
            { path: "/futuro-cliente/leads", title: "Leads", permission: readPermissions.lead },
        ],
    },
    {
        path: "/tipificaciones",
        icon: "fa fa-comment-dots",
        title: "Tipificaciones",
        children: [
            { path: "/tipificaciones/busqueda", title: "Tipificaciones", permission: readPermissions.typifications },
        ],
    },
    {
        path: "/escalamientos",
        icon: "fa fa-arrow-up",
        title: "Escalamientos",
        children: [
            { path: "/escalamientos/busqueda", title: "Escalamientos" },
        ],
    },
    {
        path: "/antiguos-clientes",
        icon: "fa fa-address-book",
        title: "Antiguos Clientes",
        children: [
            { path: "/antiguos-clientes/retiro", title: "Retiro de Equipos" }
        ],
    },
    {
        path: "/qc",
        icon: "fa fa-chart-bar",
        title: "QC",
        children: [
            { path: "/qc/inspecciones", title: "Inspecciones" },
            { path: "/qc/monitoreo", title: "Monitoreo exploratorio" }
        ]
    },
    {
        path: "/drives",
        icon: "fa fa-user-plus",
        title: "Drives",
        children: [
            { path: "/drives/definicion", title: "Definición" },
            { path: "/drives/tablas", title: "Tablas" }
        ],
    },
    {
        path: "/soporte",
        icon: "fa fa-user-plus",
        title: "Soporte Técnico",
        children: [
            { path: "/soporte", title: "Soporte" },
        ],
    },
    // {
    // 	path: "/iclass/tabla",
    // 	icon: "fa fa-user-plus",
    // 	title: "Órdenes de servicio",
    // 	children: [{ path: "/iclass/tabla", title: "Órdenes de servicio" }],
    // },
    // {
    //     path: "/horarios",
    //     icon: "fa fa-user-plus",
    //     title: "Horarios",
    //     children: [
    //         { path: "/horarios/ausencia", title: "Ausencia" },
    //         { path: "/horarios/tecnicos", title: "Técnicos" },
    //         { path: "/horarios/periodos", title: "Períodos" },
    //         { path: "/horarios/turnos", title: "Turnos" },
    //     ],
    // }
    // {
    //     path: "/cliente",
    //     icon: "fa fa-user",
    //     title: "Cliente",
    //     children: [
    //         { path: "/cliente/wizards", title: "Wizards" },
    //         {
    //             path: "/cliente/tipificaciones",
    //             title: "Tipificaciones",
    //             children: [
    //                 { path: "/cliente/todas", title: "Todas" },
    //                 { path: "/cliente/abiertas", title: "Abiertas" },
    //                 {
    //                     path: "/cliente/ultimas-24-horas",
    //                     title: "Ultimas 24 horas",
    //                 },
    //                 {
    //                     path: "/cliente/mis-tipificaciones",
    //                     title: "Mis tipificaciones",
    //                 },
    //             ],
    //         },
    //         {
    //             path: "/comunicaciones",
    //             title: "Comunicaciones",
    //             children: [
    //                 { path: "/comunicaciones/manual", title: "Manual" },
    //                 { path: "/comunicaciones/automatica", title: "Automática" },
    //             ],
    //         },
    //         { path: "/contactos", title: "Contactos" },
    //         { path: "/corte-y-reposicion", title: "Corte y reposición" },
    //     ],
    // },
    // {
    //     path: "/ex-cliente",
    //     icon: "fa fa-user-slash",
    //     title: "Ex cliente",
    //     children: [
    //         {
    //             path: "/ex-cliente/retiro-equipamiento",
    //             title: "Retiro equipamiento",
    //         },
    //         { path: "/ex-cliente/cobranza-morosos", title: "Cobranza morosos" },
    //     ],
    // },
    // {
    //     path: "/internos",
    //     icon: "fa fa-layer-group",
    //     title: "Internos",
    //     children: [
    //         { path: "/internos/escalamiento/it", title: "Escalamiento IT" },
    //         {
    //             path: "/internos/escalamiento/facturacion",
    //             title: "Escalamiento Facturación, Cobranza y Recaudación",
    //         },
    //         {
    //             path: "/internos/escalamiento/comercial",
    //             title: "Escalamiento comercial",
    //         },
    //     ],
    // },
];

export default Menu;
